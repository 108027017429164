<template>
  <div id="downloads">
    <div class="content-box">
      <div class="inner">
        <div class="meta-box">
          <div class="title">{{ $t('menu.download') }}</div>
          <el-tabs v-model="activeName" :class="['download-tabs', { 'ar': lang === 'ar' }]">
            <el-tab-pane name="app" v-if="$config.getTradingPlatform(regulator).app">
              <span slot="label" data-testid="app">{{ $t('downloads.tabTitle') }}</span>
              <div class="app-inner">
                <div class="text">
                  <div class="title">
                    {{ $t('downloads.downloadTextTitle1') }}
                  </div>
                  <div class="text-list">
                    <div>{{ $t('downloads.text1') }}</div>
                    <div>{{ $t('downloads.text2') }}</div>
                    <div>{{ $t('downloads.text3') }}</div>
                    <div>{{ $t('downloads.text4') }}</div>
                    <div>{{ $t('downloads.text5') }}</div>
                    <div>{{ $t('downloads.text6') }}</div>
                    <div>{{ $t('downloads.text7') }}</div>
                    <div>{{ $t('downloads.text8') }}</div>
                  </div>

                </div>
                <div class="download">
                  <div class="btns-wrap">
                    <div class="title">{{ $t('downloads.downloadTextTitle2') }}</div>
                    <div class="btns">
                      <a class="download-btn ios" :href="urls.app.APP_iOS" data-testid="appIos" target="_blank">
                      </a>
                      <a class="download-btn android" :href="urls.app.APP_Android" data-testid="appAndroid"
                        target="_blank">
                      </a>
                    </div>
                  </div>
                  <img class="code" src="@/assets/images/download/download-code.png" alt="">
                </div>
                <div class="img">
                  <img class="pc" src="@/assets/images/download/download-img1.png" alt="">
                  <img class="mobile" src="@/assets/images/download/download-img2.png" alt="">
                </div>
                <div class="mobile-black-bg"></div>
              </div>
            </el-tab-pane>
            <el-tab-pane name="mt4" v-if="$config.getTradingPlatform(regulator).mt4">
              <span slot="label" data-testid="mt4">METATRADER 4</span>
              <div class="mt-inner">
                <el-row :gutter="16">
                  <el-col :span="4" :lg="4" :md="8" :sm="8" :xs="12">
                    <div>
                      <div class="picture windows"></div>
                      <div class="title">Windows</div>
                      <a class="download-btn" :href="urls.mt4.METATRADER4_windows" data-testid="windows4">
                        {{ $t('propTrade.metricsPage.download') }}
                      </a>
                    </div>
                  </el-col>
                  <el-col :span="4" :lg="4" :md="8" :sm="8" :xs="12">
                    <div>
                      <div class="picture mac"></div>
                      <div class="title">Mac OS</div>
                      <a class="download-btn" :href="urls.mt4.METATRADER4_iOS" data-testid="mac4">
                        {{ $t('propTrade.metricsPage.download') }}
                      </a>
                    </div>
                  </el-col>
                  <el-col :span="4" :lg="4" :md="8" :sm="8" :xs="12">
                    <div>
                      <div class="picture linux"></div>
                      <div class="title">Linux</div>
                      <a class="download-btn" :href="urls.mt4.METATRADER4_windows" data-testid="linux4">
                        {{ $t('propTrade.metricsPage.download') }}
                      </a>
                    </div>
                  </el-col>
                  <el-col :span="4" :lg="4" :md="8" :sm="8" :xs="12">
                    <div>
                      <div class="picture iphone"></div>
                      <div class="title">iPhone/iPad</div>
                      <a class="download-btn" :href="urls.mt4.METATRADER4_iPhone" data-testid="iphone4" target="_blank">
                        {{ $t('propTrade.metricsPage.download') }}
                      </a>
                    </div>
                  </el-col>
                  <el-col :span="4" :lg="4" :md="8" :sm="8" :xs="12">
                    <div>
                      <div class="picture android"></div>
                      <div class="title">Android/Tablet</div>
                      <a class="download-btn" :href="urls.mt4.METATRADER4_Android" data-testid="android4"
                        target="_blank">
                        {{ $t('propTrade.metricsPage.download') }}
                      </a>
                    </div>
                  </el-col>
                  <el-col :span="4" :lg="4" :md="8" :sm="8" :xs="12">
                    <div>
                      <div class="picture webtrader"></div>
                      <div class="title">WebTrader</div>
                      <a class="download-btn" :href="urls.mt4.METATRADER4_WebTrader" data-testid="web4" target="_blank">
                        {{ $t('propTrade.metricsPage.download') }}
                      </a>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-tab-pane>
            <el-tab-pane name="mt5" v-if="$config.getTradingPlatform(regulator).mt5">
              <span slot="label" data-testid="mt5">METATRADER 5</span>
              <div class="mt-inner">
                <el-row :gutter="16">
                  <el-col :span="4" :lg="4" :md="8" :sm="8" :xs="12">
                    <div>
                      <div class="picture windows"></div>
                      <div class="title">Windows</div>
                      <a class="download-btn" :href="urls.mt5.METATRADER5_windows" data-testid="windows5">
                        {{ $t('propTrade.metricsPage.download') }}
                      </a>
                    </div>
                  </el-col>
                  <el-col :span="4" :lg="4" :md="8" :sm="8" :xs="12">
                    <div>
                      <div class="picture mac"></div>
                      <div class="title">Mac OS</div>
                      <a class="download-btn" :href="urls.mt5.METATRADER5_iOS" data-testid="mac5">
                        {{ $t('propTrade.metricsPage.download') }}
                      </a>
                    </div>
                  </el-col>
                  <el-col :span="4" :lg="4" :md="8" :sm="8" :xs="12">
                    <div>
                      <div class="picture linux"></div>
                      <div class="title">Linux</div>
                      <a class="download-btn" :href="urls.mt5.METATRADER5_windows" data-testid="linux5">
                        {{ $t('propTrade.metricsPage.download') }}
                      </a>
                    </div>
                  </el-col>
                  <el-col :span="4" :lg="4" :md="8" :sm="8" :xs="12">
                    <div>
                      <div class="picture iphone"></div>
                      <div class="title">iPhone/iPad</div>
                      <a class="download-btn" :href="urls.mt5.METATRADER5_iPhone" data-testid="iphone5" target="_blank">
                        {{ $t('propTrade.metricsPage.download') }}
                      </a>
                    </div>
                  </el-col>
                  <el-col :span="4" :lg="4" :md="8" :sm="8" :xs="12">
                    <div>
                      <div class="picture android"></div>
                      <div class="title">Android/Tablet</div>
                      <a class="download-btn" :href="urls.mt5.METATRADER5_Android" data-testid="android5"
                        target="_blank">
                        {{ $t('propTrade.metricsPage.download') }}
                      </a>
                    </div>
                  </el-col>
                  <el-col :span="4" :lg="4" :md="8" :sm="8" :xs="12">
                    <div>
                      <div class="picture webtrader"></div>
                      <div class="title">WebTrader</div>
                      <a class="download-btn" :href="urls.mt5.METATRADER5_WebTrader" data-testid="web5" target="_blank">
                        {{ $t('propTrade.metricsPage.download') }}
                      </a>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <InfoDialog :visible.sync="showRegisterInfoDialog" :title="$t('register.identityVerification.additionalInfo.title')"
      infoType="fail">
      <div v-html="$t('register.identityVerification.additionalInfo.content')"></div>

      <template #actions>
        <Button buttonType="default" @click="goToRegistration">
          {{ $t('verificationStatus.kyc.start') }}
        </Button>
      </template>
    </InfoDialog>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';
import InfoDialog from '@/components/dialog/InfoDialog.vue';
import { mapState, mapActions } from 'vuex';
import { generalRegisterDirectPage } from '@/util/register';
import { registerConfig } from '../constants/register';

export default {
  name: 'downloads',
  components: { InfoDialog, Button },
  data() {
    return {
      activeName: 'app',
      showRegisterInfoDialog: false
    };
  },
  computed: {
    ...mapState('register', ['currStep']),
    urls() {
      return this.$config.getMetaTraderLinks(this.regulator, this.GLOBAL_DOMAIN_WEBSITE);
    },
    lang() {
      return this.$store.state.common.lang;
    }
  },
  mounted() {
    this.init();
    if (this.$config.getTradingPlatform(this.regulator).app) {
      this.activeName = 'app'
    } else if (this.$config.getTradingPlatform(this.regulator).mt4) {
      this.activeName = 'mt4'
    } else if (this.$config.getTradingPlatform(this.regulator).mt5) {
      this.activeName = 'mt5'
    } else {
      console.log('no trading platform')
    }
  },
  methods: {
    ...mapActions('register', ['actionAccountAuditStatus']),
    async init() {
      await this.actionAccountAuditStatus();
      if (this.currStep < registerConfig.identityVerification.frontendStep) this.showRegisterInfoDialog = true;
    },
    goToRegistration() {
      let router = generalRegisterDirectPage({ isIBT: false });
      return router ? this.$router.push({ name: router }) : false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/downloads.scss';
</style>
